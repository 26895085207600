/**
 * Set background color based on whether or not lighting_hours under maximum
 * @param {number} maximum_light_hours  Maximum number of lighting_hours
 * @param {number} value                Actual number of lighting_hours
 * @returns {string}                    Class for background color
 */
function lighting_hours_class(maximum_light_hours, value) {
  return (!(value === null || isNaN(value)) && value > maximum_light_hours) ? 'bg-red-200' : 'bg-transparent';
}

/**
 * Set text class based on whether energy_diff is too high
 * @param {Object} derived  Object describing derived quantities of plan
 * @param {int} i           Index of value to look at
 * @returns {string}        Object describing text font and color
 */
function energy_diff_class(derived, i) {
  const diff = derived['energy_diff'][i];
  const ref = derived['energy_needed'][i];

  if (diff < -ref / 10) {
    return {'font-bold text-red-500': true, 'font-normal text-gray-500': false};
  } else if (diff < -ref / 20) {
    return {'text-red-500': true, 'text-gray-500': false};
  }
}

/**
 * Set class based on whether value is within given boundaries
 * @param {Object[]} validation_list    List of key-value pairs describing boundaries
 * @param {number} val                  Number to test against boundaries
 * @returns {string}                    Class for background color
 */
function within_boundaries(validation_list, val) {
  let cls = '';
  validation_list.map((validator) => {
    validate_option(validator, val) ? '' : cls = 'bg-red-200';
  });
  return cls;
}

/**
 * Return whether or not a given value adheres to a given validation
 * @param {Array<str|number>} validator   Key value pair describing boundary
 * @param {number} val                    Value to be tested against boundaries
 * @returns {bool}                        Whether or not value adheres to given boundary
 */
function validate_option(validator, val) {
  const key = validator[0];
  const value = validator[1];
  let res = true;
  if (val != '') {
    switch (key) {
      case 'greater_than_or_equal_to':
        res = val >= value;
        break;
      case 'greater_than':
        res = val > value;
        break;
      case 'less_than_or_equal_to':
        res = val <= value;
        break;
      case 'less_than':
        res = val < value;
        break;
    }
  }
  return res;
}

const ValidationCss = {
  energy_diff_class: energy_diff_class,
  lighting_hours_class: lighting_hours_class,
  within_boundaries: within_boundaries
};

window.validation_css = ValidationCss;
module.exports = ValidationCss;
