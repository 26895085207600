function registration_needs_warning(save_status) {
  return Object.values(save_status).some(function(x) {
    return x == 'has_changes';
  });
}

const save_status_class_dict = {
  new: 'bg-white',
  has_changes: 'bg-yellow-100',
  saving: 'bg-yellow-100',
  saved: 'bg-green-400',
  error: 'bg-red-400'
};

const save_status_sym_dict = {
  new: 'o',
  has_changes: '!',
  saving: '...',
  saved: '\u2713',
  error: 'x'
};

// alpine store to keep track of which crop is being registered
window.Alpine.store('selected_crop_id', 'nil');

window.Alpine.magic('save_status_class_dict', () => {
  return save_status_class_dict;
});

window.Alpine.magic('save_status_sym_dict', () => {
  return save_status_sym_dict;
});

window.Alpine.magic('registration_needs_warning', () => {
  return (save_status) => registration_needs_warning(save_status);
});
