/* global  calc, graphs, utils */

// define the interval of rows being filled in when initializing the plan
const prefilled_plan_row_interval = 4;

/**
 * Prefill the temperature value based on minimization of energy_diff
 * @param {Object} plan            Plan object
 * @param {Object} linear          Use linear_interpolation if true, firstValueUp if false
 * @param {Object} crop            Crop object
 * @param {Object} plant_profile   PlantProfile object
 * @param {Object[]} lights        Array of Light objects
 * @returns {Object}               Updated plan object
 */
function prefill_temperature(plan, linear, crop, plant_profile, lights) {
  const derived_plan = calc.derive(plan, linear, crop, plant_profile, lights);
  plan.temperature.map((T, i) => (i % prefilled_plan_row_interval == 0) ?
  plan.temperature[i] = utils.round(graphs.calc_optimal_temp(derived_plan.energy_available[i], plant_profile), 1) :
  ''
  );
  return plan;
}

/**
 * Prefill the lighting_hours value based on minimization of energy_diff
 * @param {Object} plan            Plan object
 * @param {Object} linear          Use linear_interpolation if true, firstValueUp if false
 * @param {Object} crop            Crop object
 * @param {Object} plant_profile   PlantProfile object
 * @param {Object[]} lights        Array of Light objects
 * @returns {Object}               Updated plan object
 */
function prefill_lighting_hours(plan, linear, crop, plant_profile, lights) {
  if (lights.length === 0) {
    return plan;
  }

  const derived_plan = calc.derive(plan, linear, crop, plant_profile, lights);
  const eff_outside_light = calc.outside_light_inside(plan, crop);
  lights = lights.sort((a, b) => a.light_num - b.light_num);
  const lighting_joules = new Array(crop.duration).fill(0);
  for (let j = 0; j < lights.length; j++) {
    const light = lights[j];
    const light_num = light.light_num;
    const light_var_name = `lighting_hours_${light_num}`;
    let power = 0;
    let efficiency = 1;
    if (typeof light !== 'undefined') {
      power = parseFloat(light['power']);
      efficiency = parseFloat(light['efficiency']);
    }

    for (let i = 0; i < crop.duration; i++) {
      if (i % prefilled_plan_row_interval !== 0) {
        continue;
      }
      let additional_lighting_hours = utils.round(
          Math.max(derived_plan.energy_needed[i] - eff_outside_light[i] - lighting_joules[i], 0) *
          efficiency / power,
          1
      );
      additional_lighting_hours = Math.min(additional_lighting_hours, light.maximum_light_hours);
      if (additional_lighting_hours < 2) additional_lighting_hours = 0;
      additional_lighting_hours = (Math.round(additional_lighting_hours * 4) / 4).toFixed(2);
      lighting_joules[i] += additional_lighting_hours * power / efficiency;
      plan[light_var_name][i] = additional_lighting_hours;
    }
  }
  return plan;
}

/**
 * Prefill the growing_period value based on minimization of energy_diff
 * @param {Object} plan            Plan object
 * @param {Object} linear          Use linear_interpolation if true, firstValueUp if false
 * @param {Object} crop            Crop object
 * @param {Object} plant_profile   PlantProfile object
 * @returns {Object}               Updated plan object
 */
function prefill_growing_period(plan, linear, crop, plant_profile) {
  if (crop.use_degree_days) {
    const grow_period = calc.calc_grow_period(plan, linear, plant_profile);
    plan.temperature.map((T, i) => (i % prefilled_plan_row_interval == 0) ?
      plan.grow_period[i] = grow_period[i] :
      ''
    );
  }
  return plan;
}

/**
 * Prefill the various values based on minimization of energy_diff
 * @param {Object} plan            Plan object
 * @param {Object} linear          Use linear_interpolation if true, firstValueUp if false
 * @param {Object} crop            Crop object
 * @param {Object} plant_profile   PlantProfile object
 * @param {Object[]} lights        Array of Light objects
 * @returns {Object}               Updated plan object
 */
function prefill_all(plan, linear, crop, plant_profile, lights) {
  // repeat once since they are dependent on each other
  const repeat = 2;
  for (let i = 0; i < repeat; i++) {
    // temperature is dependent on light available
    plan = prefill_temperature(plan, linear, crop, plant_profile, lights);
    // lighting hours is dependent on temperature amongst other things
    plan = prefill_lighting_hours(plan, linear, crop, plant_profile, lights);
    // grow period is dependent on temperature, degree days and expected weight
    plan = prefill_growing_period(plan, linear, crop, plant_profile);
  }
  return plan;
}

const Init = {
  prefill_all: prefill_all,
  prefill_growing_period: prefill_growing_period,
  prefill_lighting_hours: prefill_lighting_hours,
  prefill_temperature: prefill_temperature
};

window.init = Init;
module.exports = Init;
