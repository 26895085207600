function add_patch_to_realized_row_form(form) {
  // Add a _method patch input field to a create form to change it to an update form
  const el = document.createElement('input');
  el.type = 'hidden';
  el.name = '_method';
  el.value = 'patch';
  form.appendChild(el);
  const week_number = document.getElementById('realized_row_week_number').value;
  form.action += '/' + week_number;
}

window.form_funcs = {
  add_patch_to_realized_row_form: add_patch_to_realized_row_form
};

/* Suggestions */
window.Alpine.magic('realized_row_suggestions', () => {
  return (crop_data, var_name, custom = false) => {
    const map = new Map();
    Object.keys(crop_data).forEach((key) => {
      let base = crop_data[key];
      if (custom) base = base['custom_vars'];

      const val = base[var_name];
      const crop_name = crop_data[key]['name'];

      if (!val) return;

      if (map.has(val)) {
        map.set(val, map.get(val) + ', ' + crop_name);
      } else {
        map.set(val, crop_name);
      }
    });
    // note that the below orders by number of commas, which gets funky if crop names contain many commas.
    return Array.from(map.entries()).sort((a, b) => b[1].split(',').length - a[1].split(',').length).slice(0, 4);
    // converting to array because map.map did not seem to work
  };
});

function suggestionInput() {
  return {
    generateSuggestions(suggestions = []) {
      let suggestionsDiv = this.$el.parentNode.querySelector('.suggestions');
      if (!suggestionsDiv) {
        suggestionsDiv = document.createElement('div');
        suggestionsDiv.className = 'suggestions absolute inset-y-0 right-0 flex py-1.5 pr-1.5';
        this.$el.after(suggestionsDiv);
      }

      suggestionsDiv.innerHTML = suggestions.map(([value, label]) =>
        `<div
          class="inline-flex items-center rounded border border-gray-200 ml-1 px-1
          font-sans text-xs text-gray-400 cursor-pointer hover:bg-gray-100"
          x-data
          @click="e = $el.parentNode.parentNode.querySelector('input');
          if (e.value != '${value}') { e.value = '${value}'; e.dispatchEvent(new Event('change', { bubbles: true })); }"
          title='${label}'
        ><span class="pointer-events-none">${value}</span></div>`
      ).join('');
    }
  };
}
window.suggestionInput = suggestionInput;
